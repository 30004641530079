import axios from 'axios';

import { log } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';

export const getDailyDistrictBalanceReport = async (districtId: string) => {
  const graphqlQuery = `
    query fetchDistrictBalanceReport($orgType: OrgType!, $orgId: String!) {
      clubBalanceReport: getClubBalanceReport(orgType: $orgType, orgId: $orgId) {
        FileContent
      }
    }
  `;

  const graphqlEndpoint = `${process.env.GATSBY_MRX_API_BUILD_URL}/graphql`;

  try {
    const pdfVariables = {
      orgType: DIS.OrganizationTypeEnum.District,
      orgId: districtId,
    };

    try {
      const pdfResponse = await axios.post(
        graphqlEndpoint,
        {
          query: graphqlQuery,
          variables: pdfVariables,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const pdfData = pdfResponse.data.data.clubBalanceReport;

      if (pdfData) {
        const pdfWindow = window.open('', '_blank');
        if (pdfWindow) {
          pdfWindow.document.write(
            `<iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURIComponent(
              pdfData?.FileContent ?? ''
            )}"></iframe>`
          );
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        log({
          level: LogLevel.Debug,
          message: `Gatsby: districtBalanceReport, 'GraphQL Request Failed:', ${error.message}`,
        });
      } else {
        log({
          level: LogLevel.Debug,
          message: `Gatsby: districtBalanceReport, 'Unknown error occurred:', ${error}`,
        });
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchDistrictBalanceReport, 'GraphQL Request Failed:', ${error.message}`,
      });
    } else {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchDistrictBalanceReport, 'Unknown error occurred:', ${error}`,
      });
    }
  }
};
